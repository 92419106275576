import React, { useState } from 'react';
import Home from './Home';
import About from './About';
import Services from './Services';
import Contact from './Contact';

const Tabs: React.FC = () => {
  // Set up the state to track the active tab
  const [activeTab, setActiveTab] = useState('Home');

  // Handler for switching tabs
  const handleTabClick = (tab: string, event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault(); // Prevent default focus behavior
    setActiveTab(tab);
  };

  // Render the tab component
  return (
    <>
    <div className="w-full bg-black">
      {/* Tab buttons */}
      <div className="flex justify-around max-w-screen-lg mx-auto">
        {['Home', 'About', 'Service', 'Contact'].map((tab) => (
          <button
            key={tab}
            onClick={(event) => handleTabClick(tab, event)}
            className={`py-2 px-4 text-md font-medium w-full ${
              activeTab === tab ? 'bg-red-500 text-black' : 'text-white'
            }`}
          >
            {tab}
          </button>
        ))}
      </div>
    </div>
      {/* Tab content */}
      <div className="p-4 bg-black">
        {activeTab === 'Home' && <Home />}
        {activeTab === 'About' && <About />}
        {activeTab === 'Service' && <Services />}
        {activeTab === 'Contact' && <Contact />}
      </div>
      </>
  );
};

export default Tabs;
