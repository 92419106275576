import React from "react";

const Header: React.FC = () => {
  return (
    <header className="bg-black p-4 text-white">
      <div className="flex flex-col items-center md:flex-row md:justify-between space-x-2 max-w-7xl mx-auto">
        {/* Reference image in the `public` folder */}
        <img
          src="/cklogo.jpg"
          alt="CkautoImage logo"
          className="min-h-4 max-h-24 max-w-60 object-contain"
        />
        <div className="flex flex-col justify-center">
            <p className="text-lg font-semibold italic">MAXIMIZE YOUR RIDE</p>
            <p className="text-sm">215-627-2258</p>
            <p className="text-sm">
              718 Washington Ave, Philadelphia, PA 19147
            </p>
        </div>
      </div>
    </header>
  );
};

export default Header;
