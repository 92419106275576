import React from "react";

const Services: React.FC = () => {
  const serviceList = [
    {
      id: 1,
      title: "PA State Inspection",
      description:
        "At our station we are certified to perform both PA State Safety & Emission inspections. Making sure your vehicle is ready for the year ahead!",
    },
    {
      id: 2,
      title: "Repair & Maintenance",
      description:
        "We specialize in keeping your vehicle safe and reliable with routine maintenance, service and repairs. Alignments to Road Force Balancing we have all the equipment to make sure your car rides as good as the day you bought it!",
    },
    {
      id: 3,
      title: "Tire & Wheels",
      description: "We carry a wide selection of brand name tires, catering to all passenger, touring and truck tires and wheels to fit your needs.",
    },
    {
      id: 4,
      title: "Mobile Electronics",
      description: "We are an authorized dealer for leading manufacturers such as, Kenwood, Viper, Clifford and Hertz Audison to name a few.",
    },
    {
      id: 5,
      title: "Electrical Diagnostics",
      description: "With our full line of diagnostic tools and our trained technicians, we are more than capable to find that electrical gremlin haunting your vehicle.",
    },
    {
      id: 6,
      title: "Performance Upgrade",
      description: "Our in-house staff can assist you on improving the look, sound and performance of your vehicle. Need an exhaust? No Problem! Suspension? Sure, full race to springs only!",
    },
  ];

  return (
    <div className="p-4 bg-black">
      <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-3 max-w-screen-lg mx-auto">
        {serviceList.map((service) => (
          <div
            key={service.id}
            className="p-4 rounded-lg shadow-md text-center"
          >
            <h3 className="text-lg text-white font-semibold mb-2">
              {service.title}
            </h3>
            <p className="text-white">{service.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
