import React, { useEffect, useRef, useState } from "react";

// Sample images, replace with actual paths
const facilityInside = "about/ck-inside.jpg";
const facilityOutside = "about/ck-outside.jpg";

const About: React.FC = () => {
  const imagesRef = useRef<(HTMLImageElement | null)[]>([]);
  const [isVisible, setIsVisible] = useState<boolean[]>([false, false]);

  useEffect(() => {
    // Copy the current ref value to a variable
    const images = imagesRef.current;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry, index) => {
        if (entry.isIntersecting) {
          setIsVisible((prev) => {
            const updated = [...prev];
            updated[index] = true;
            return updated;
          });
        }
      });
    });

    images.forEach((img) => {
      if (img) observer.observe(img);
    });

    return () => {
      images.forEach((img) => {
        if (img) observer.unobserve(img);
      });
    };
  }, []);
  const aboutInfo = [
    {
      id: 1,
      content:
        "Family owned since 2001, CK Auto Image was established in Philadelphia, Pennsylvania.",
    },
    {
      id: 2,
      content:
        "We are conveniently located next to the Italian market and dine-in restaurants while you are waiting for your vehicle.",
    },
    {
      id: 3,
      content:
        "Our clean, comfortable waiting lounge area offers free Wi-Fi, television, current magazines, and entertainment systems.",
    },
    {
      id: 4,
      content:
        "We offer complimentary refreshments for all our customers, striving to make it the most comfortable experience as possible!",
    },
  ];

  return (
    <div className="p-4 bg-black">
      {/* Text blocks */}
      <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-4 max-w-screen-xl mx-auto mb-4">
        {aboutInfo.map((info) => (
          <div key={info.id} className="p-4 rounded-lg shadow-md text-center">
            <p className="text-white">{info.content}</p>
          </div>
        ))}
      </div>
      {/* Images */}
      <div className="text-red-500 font-bold max-w-screen-md mx-auto mb-2">
        Our Facility
      </div>
      <div className="flex flex-col max-w-screen-md mx-auto">
        <img
          ref={(el) => (imagesRef.current[0] = el)}
          src={facilityInside}
          alt="Inside Facility"
          className={`w-full h-auto object-cover rounded-lg shadow-md transition-opacity duration-1000 mb-4 ${
            isVisible[0] ? "opacity-100" : "opacity-0"
          }`}
          loading="lazy"
        />
        <img
          ref={(el) => (imagesRef.current[1] = el)}
          src={facilityOutside}
          alt="Outside Facility"
          className={`w-full h-auto object-cover rounded-lg shadow-md transition-opacity duration-1000 ${
            isVisible[1] ? "opacity-100" : "opacity-0"
          }`}
          loading="lazy"
        />
      </div>
    </div>
  );
};

export default About;
