import Header from './components/Header';
import Hero from './components/Hero';
import Tabs from './components/Tabs';
import Footer from './components/Footer';

const App: React.FC = () => {
  return (
    <div className="App">
      <Header />
      <Hero />
      <Tabs />
      <Footer />
    </div>
  );
};

export default App;
