import React, { useEffect, useRef } from "react";

declare global {
  interface Window {
    initMap: () => void;
  }
}

const Contact: React.FC = () => {
  const mapRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const loadGoogleMapsScript = () => {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBEGc16Wg7F-5b4Fco4XjaNWR4cUVkhmpg&callback=initMap`;
      script.async = true;
      script.defer = true;
      window.initMap = initMap; // Assign initMap to window object
      document.head.appendChild(script);
    };

    const initMap = () => {
      if (mapRef.current) {
        const ckautoimage = { lat: 39.935599, lng: -75.156152 };
        const map = new google.maps.Map(mapRef.current, {
          zoom: 16,
          center: ckautoimage,
        });
        // Add a marker at the given coordinates
        new google.maps.Marker({
          position: { lat: 39.9351, lng: -75.1562 },
          map: map,
          title: "CK Auto Image",
        });
      }
    };

    if (!window.google) {
      loadGoogleMapsScript();
    } else {
      initMap();
    }
  }, []);

  return (
    <div className="p-4 bg-black">
      <div className="max-w-screen-xl mx-auto">
        {/* Map and Contact Info */}
        <div className="flex flex-col lg:flex-row gap-6">
          {/* Custom Google Map */}
          <div className="flex-1">
            <div
              ref={mapRef}
              className="w-full h-64 lg:h-full rounded-lg shadow-md"
            ></div>
          </div>

          {/* Contact Info */}
          <div className="flex-1 bg-slate-800 p-6 rounded-lg shadow-md">
            <h3 className="text-lg text-white font-semibold mb-4">
              Contact Information
            </h3>
            <p className="text-white mb-2">718 Washington Avenue,</p>
            <p className="text-white mb-4">Philadelphia, PA 19147</p>
            {/* Get Directions Link */}
            <div className="mb-4">
              <a
                href="https://www.google.com/maps/dir/?api=1&destination=718+Washington+Avenue,+Philadelphia,+PA+19147"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white underline"
              >
                Get Directions
              </a>
            </div>
            <p className="text-white text-lg mb-2">
              <strong>Primary:</strong> 215 627 2258
            </p>
            <p className="text-white mb-2">
              <strong>Secondary:</strong> 215 218 9998
            </p>
            <p className="text-white mb-2">
              <strong>Email:</strong> ckautoimage@gmail.com
            </p>
            <p className="text-white">
              <strong>FAX:</strong> 215 627 2257
            </p>
          </div>
        </div>
      </div>

      {/* Social Media Links */}
      <div className="mt-6 text-center flex justify-center space-x-4">
        <a
          href="https://www.facebook.com/maximizingyouride/"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-600 hover:underline"
        >
          <img
            src="Facebook_Logo_Primary.png"
            alt="Facebook"
            className="w-8 h-8"
          />
        </a>
        <a
          href="https://www.yelp.com/biz/ck-auto-image-philadelphia"
          target="_blank"
          rel="noopener noreferrer"
          className="text-red-600 hover:underline"
        >
          <img src="yelp_burst.png" alt="Yelp" className="w-8 h-8" />
        </a>
        <a
          href="https://www.instagram.com/ckautoimage/"
          target="_blank"
          rel="noopener noreferrer"
          className="text-pink-600 hover:underline"
        >
          <img
            src="Instagram_Glyph_Gradient.png"
            alt="Instagram"
            className="w-8 h-8"
          />
        </a>
      </div>
    </div>
  );
};

export default Contact;
