import React from "react";
const backgroundImage = "/engine_bay.jpg";

const Hero: React.FC = () => {
  return (
    <div
      className="relative flex items-center justify-center h-[400px] bg-cover bg-center bg-no-repeat"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      {/* Dark overlay text box */}
      <div className="absolute bottom-0 left-0 right-0 lg:bottom-1/3 bg-black bg-opacity-70 p-4 text-center text-white">
        <h2 className="text-xl md:text-2xl uppercase max-w-[920px] mx-auto">
          Count on our team of experts to provide all the <span className="text-red-500">auto repairs</span> you need
          for your vehicle
        </h2>
        <p className="text-md md:text-lg font-semibold">Team <span className="text-red-500">CK Auto Image</span></p>
      </div>
    </div>
  );
};

export default Hero;
