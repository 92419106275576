import React from "react";

// Sample images, replace with actual paths
const equipmentSrc = "home/equipment.jpg";
const oilSrc = "home/oils.jpg";
const roadAssistSrc = "home/road-assist.png";
const camaroSrc = "home/camaro.jpg";

const Home: React.FC = () => {
  const tiles = [
    {
      id: 1,
      title: "Quality Equipment",
      description:
        "Our advanced tools and equipment provide our clients fast and high-quality services.",
      image: equipmentSrc,
    },
    {
      id: 2,
      title: "All Year Makes & Model",
      description:
        "Our technicians are trained to perform repairs across all years, makes and models.",
      image: oilSrc,
    },
    {
      id: 3,
      title: "Nationwide 24-Hr Roadside Assistance",
      description:
        "We go the extra mile to stand behind our work, providing our clients 24-Hr roadside assistance.",
      image: roadAssistSrc,
    },
    {
      id: 4,
      title: "Our Annual Car Show",
      description:
        "Every year we give back to our community with our annual car show hosted during the Italian Market Festival.",
      image: camaroSrc,
    },
  ];

  return (
    <div className="bg-black">
      <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-2 p-4 max-w-screen-xl mx-auto">
        {tiles.map((tile) => (
          <div
            key={tile.id}
            className="flex flex-col sm:flex-row items-center p-4 rounded-lg shadow-md"
          >
            <div className="flex flex-col mb-4">
              <h3 className="text-lg text-white font-semibold mb-2">
                {tile.title}
              </h3>
              <p className="text-white">{tile.description}</p>
            </div>
            <img
              src={tile.image}
              alt={tile.title}
              className="w-full p-4 sm:p-0 sm:w-1/3 sm:mb-0 sm:ml-4 lg:min-h-40 object-cover"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Home;
